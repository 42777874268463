import ReactGA from "react-ga";

// import auth from './auth.ts'; // Sample authentication provider

function isProd() {
  return process.env.NODE_ENV && process.env.NODE_ENV !== "development";
}

export function init() {
  const trackingId = "UA-132339831-3"; // Replace with your Google Analytics tracking ID

  if (isProd()) {
    ReactGA.initialize(trackingId);
  }
  // ReactGA.set({
  //     // userId: auth.currentUserId(),
  //     // any data that is relevant to the user session
  //     // that you would like to track with google analytics
  // });
}

export function reportPageHit() {
  const page = window.location.pathname + window.location.search;
  if (isProd()) {
    ReactGA.pageview(page);
  }
}

// export function reportClick(buttonName) {
//     const page = window.location.pathname + window.location.search;
//     const event = page + ":" + buttonName;
//     console.log('Click - ' + event);
//     ReactGA.event({
//         category: event,
//         action: "User pressed button",
//     });
// }

import React, { Component } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import CustomNavbar from "./component/CustomNavbar";
import Banner from "./component/Banner/Banner";
import About from "./component/About";
import Features from "./component/Features";
import Faq from "./component/Faq/Faq";
// import Price from './component/Price';
import Action from "./component/Action";
// import AppStore from './component/AppStore';
// import Testimonial from './component/Testimonial/Testimonial';
import Footer from "./component/Footer/Footer";
import $ from "jquery";

import WOW from "wowjs";
import PrivacyPolicy from "./component/Text/PrivacyPolicy";
import TermsAndConditions from "./component/Text/TermsAndConditions";
import * as Analytics from "./Analytics";
import { reportPageHit } from "./Analytics";
import packageJson from "../package.json";

class App extends Component {
  componentDidMount() {
    if (typeof window !== "undefined") {
      const wow = new WOW.WOW({
        live: false,
      });
      wow.init();
    }
    var head = $(".navbar");
    var stick = "shrink";

    $(window).scroll(function () {
      $(window).scrollTop() > 0
        ? head.addClass(stick)
        : head.removeClass(stick);
    });

    $(
      ".navbar li a, #site-icon, #nav-bar-action-button, #about-action-button, .n_banner_content a",
    ).on("click", function (event) {
      var $anchor = $(this);
      var section = $($anchor.attr("href"));
      $("html, body")
        .stop()
        .animate(
          {
            scrollTop: section.offset().top - section.data("offset"),
          },
          1500,
        );
      event.preventDefault();
    });

    Analytics.init();
    reportPageHit();
  }

  render() {
    return (
      <Router>
        <div className="App">
          <div className="body_wrapper">
            <Routes>
              <Route
                path="/privacy"
                element={
                  <>
                    <CustomNavbar textPage={true} />
                    <PrivacyPolicy />
                  </>
                }
              />
              <Route
                path="/privacy-policy"
                element={
                  <>
                    <CustomNavbar textPage={true} />
                    <PrivacyPolicy />
                  </>
                }
              />
              <Route
                path="/terms-conditions"
                element={
                  <>
                    <CustomNavbar textPage={true} />
                    <TermsAndConditions />
                  </>
                }
              />
              <Route
                path="/terms"
                element={
                  <>
                    <CustomNavbar textPage={true} />
                    <TermsAndConditions />
                  </>
                }
              />
              <Route
                path="/"
                element={
                  <>
                    <CustomNavbar />
                    <Banner />
                    <About />
                    <Features />
                    {/*<Price/>*/}
                    <Action />
                    <Faq />
                    {/*<AppStore/>*/}
                    {/*<Testimonial/>*/}
                  </>
                }
              />
            </Routes>
            <Footer version={packageJson.version} />
          </div>
        </div>
      </Router>
    );
  }
}

export default App;

import React, { Component } from "react";
import HorizontalIconWhite from "../images/screenshots/horizontal-icon-white.png";
import HorizontalIcon from "../images/screenshots/horizontal-icon.png";

class CustomNavbar extends Component {
  render() {
    const c = this.props.textPage ? "shrink" : "";
    return (
      <nav
        id="fixed-top"
        className={
          "navbar navbar-toggleable-sm transparent-nav navbar-expand-lg " + c
        }
      >
        <div className="container">
          <a href={this.props.textPage ? "/" : "#home"} id="site-icon">
            <span className="navbar-brand">
              <img src={HorizontalIconWhite} alt="logo" height="80" />
              <img src={HorizontalIcon} alt="logo" height="80" />
            </span>
          </a>
          {/*<button className="navbar-toggler collapsed" type="button" data-toggle="collapse"*/}
          {/*        data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"*/}
          {/*        aria-expanded="false" aria-label="Toggle navigation">*/}
          {/*    <span className="icon-bar"></span>*/}
          {/*    <span className="icon-bar"></span>*/}
          {/*    <span className="icon-bar"></span>*/}
          {/*</button>*/}
          {this.props.textPage ? getTextPageButtons() : getButtons()}
        </div>
      </nav>
    );
  }
}

function getButtons() {
  return (
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
      <ul className="navbar-nav ml-auto">
        {/*<li className="nav-item dropdown submenu">*/}
        {/*    <a className="nav-link dropdown-toggle active"*/}
        {/*       href="#home" role="button"*/}
        {/*       data-toggle="dropdown" aria-haspopup="true"*/}
        {/*       aria-expanded="false">Home</a>*/}
        {/*    <ul className="dropdown-menu sub-menu">*/}
        {/*        <li><a href="home.html">Home 01</a></li>*/}
        {/*        <li><a href="home-2.html">Home 02</a></li>*/}
        {/*        <li><a href="index.html">Home 03</a></li>*/}
        {/*        <li><a href="index3.html">Home 04</a></li>*/}
        {/*        <li><a href="index5.html">Home 05</a></li>*/}
        {/*        <li><a href="index6.html">Home 06</a></li>*/}
        {/*        <li><a href="index7.html">Home 07</a></li>*/}
        {/*        <li><a href="index8.html">Home 08</a></li>*/}
        {/*    </ul>*/}
        {/*</li>*/}
        <li className="nav-item">
          <a className="nav-link" href="#about">
            About
          </a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="#features">
            Features
          </a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="#faq">
            FAQ
          </a>
        </li>
        {/*<li className="nav-item"><a className="nav-link" href="#reviews">Reviews</a></li>*/}
        {/*<li className="nav-item"><a className="nav-link" href="#download">Download</a></li>*/}
      </ul>
      {/*Once web implementd, return the Download link above*/}
      <a href="#download" className="get-btn" id="nav-bar-action-button">
        Download
      </a>
    </div>
  );
}

function getTextPageButtons() {
  return (
    <div className="collapse navbar-collapse">
      <ul className="navbar-nav ml-auto"></ul>
      <a href="/" className="get-btn">
        Go to Home Page
      </a>
    </div>
  );
}

export default CustomNavbar;

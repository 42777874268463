import React, { Component } from "react";
import { Link } from "react-router-dom";
import AndroidStoreIcon from "../../images/screenshots/app-icon.png";

class Footer extends Component {
  render() {
    return (
      <footer className="footer-five">
        <div className="footer-top">
          <div className="container">
            <div
              className="row footer_sidebar"
              style={{ justifyContent: "space-between" }}
            >
              <div className="widget footer-widget col-xs-6 col-sm-6 col-md-3 wow fadeIn widget1 about_us_widget">
                <Link to="/">
                  <span className="logo">
                    <img
                      className="footer-logo"
                      src={AndroidStoreIcon}
                      alt="AppArt"
                    />
                  </span>
                </Link>
                <p>
                  {" "}
                  {"This app is made with love by the Warranty Keeper Team <3"}
                </p>
                <br />v{this.props.version}
              </div>
              {/*<div*/}
              {/*    className="widget footer-widget col-xs-6 col-sm-6 col-md-3 wow fadeIn widget4 widget_social">*/}
              {/*    <h4 className="widget_title_two">Social Link</h4>*/}
              {/*    <ul className="social-icon">*/}
              {/*        <li><a href="."><i className="fa fa-facebook"/>Facebook</a></li>*/}
              {/*        <li><a href="."><i className="fa fa-instagram"/> Instagram </a></li>*/}
              {/*        <li><a href="."><i className="fa fa-linkedin"/> Linkedin </a></li>*/}
              {/*        <li><a href="."><i className="fa fa-pinterest"/> Pinterest </a></li>*/}
              {/*    </ul>*/}
              {/*</div>*/}
              <div
                className="widget footer-widget col-xs-6 col-sm-6 col-md-3 wow fadeIn widget_nav_menu"
                data-wow-delay="0ms"
                data-wow-duration="1500ms"
                data-wow-offset="0"
              >
                <h4 className="widget_title_two">Quick Links</h4>
                <div className="menu-quick-links-container">
                  <ul id="menu-quick-links" className="menu">
                    <li id="menu-item-844" className="menu-item">
                      <a href="https://docs.google.com/forms/d/e/1FAIpQLSeANGrfeL7XwNkin9iDcDeZe7f70dRZY1OqwEIu25nZ7fuhNQ/viewform">
                        Translate the App
                      </a>
                    </li>
                    <li id="menu-item-845" className="menu-item">
                      <a href="/terms-conditions">Terms and Conditions</a>
                    </li>
                    <li id="menu-item-846" className="menu-item">
                      <a href="/privacy-policy">Privacy Policy</a>
                    </li>
                    {/*<li id="menu-item-847" className="menu-item"><a href=".">Tags</a></li>*/}
                  </ul>
                </div>
              </div>
              <div className="widget footer-widget col-xs-6 col-sm-6 col-md-3 wow fadeIn widget2 widget_contact">
                <h4 className="widget_title_two">Contact Info</h4>
                <div className="widget_inner row m0">
                  <ul>
                    {/*<li><i className="ti-location-pin"/>*/}
                    {/*    <div className="location_address fleft">25 Bedford St. New York City, N.Y.*/}
                    {/*        United States Of America*/}
                    {/*    </div>*/}
                    {/*</li>*/}
                    {/*<li><i className="ti-mobile"/>*/}
                    {/*    <div className="fleft contact_no">+88-017812-37134</div>*/}
                    {/*</li>*/}
                    <li>
                      <i className="ti-email" />
                      <div className="fleft contact_mail">
                        <a href="mailto:app@warranty-keeper.com">
                          {" "}
                          app@warranty-keeper.com{" "}
                        </a>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row m0 footer_bottom text-center">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <p>
                  {new Date().getFullYear()} <a href=".">Warranty Keeper</a> All
                  rights reserved
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;

import React, { Component } from "react";
import Line1 from "../images/line/line_01.png";
import Line2 from "../images/line/line_02.png";
import Line3 from "../images/line/line_03.png";
import Line4 from "../images/line/line_04.png";
import Line5 from "../images/line/line_05.png";
import Line6 from "../images/line/line_06.png";
import Shape from "../images/line/shape.png";
import Shape1 from "../images/line/shape-1.png";
import Shape3 from "../images/shape3.png";
import ThreeScreenshots from "../images/screenshots/Three-Screenshots.png";

class About extends Component {
  render() {
    return (
      <section className="about_area" id="about" data-offset={150}>
        <ul className="memphis-parallax hidden-xs hidden-sm white_border">
          <li data-parallax='{"x": -100, "y": 100}'>
            <img className="br_shape" src={Line1} alt="f_img" />
          </li>
          <li data-parallax='{"x": -200, "y": 200}'>
            <img className="br_shape" src={Line2} alt="f_img" />
          </li>
          <li data-parallax='{"x": -150, "y": 150}'>
            <img className="br_shape" src={Line3} alt="f_img" />
          </li>
          <li data-parallax='{"x": 50, "y": -50}'>
            <img className="br_shape" src={Line4} alt="f_img" />
          </li>
          <li data-parallax='{"x": -150, "y": 150}'>
            <img src={Line5} alt="f_img" />
          </li>
          <li data-parallax='{"x": -100, "y": 100}'>
            <img src={Line6} alt="f_img" />
          </li>
          <li data-parallax='{"x": 50, "y": -50}'>
            <img src={Shape} alt="f_img" />
          </li>
          <li data-parallax='{"x": 250, "y": -250}'>
            <img src={Shape1} alt="f_img" />
          </li>
        </ul>
        <img className="shape wow fadeInRight" src={Shape3} alt="f_img" />
        <div className="container">
          <div className="row row_reverse align_items_center">
            <div className="col-lg-7">
              <div className="about_img text-right">
                <img
                  src={ThreeScreenshots}
                  style={{ maxHeight: 796 }}
                  alt="f_img"
                />
              </div>
            </div>
            <div className="col-lg-5">
              <div className="about_content">
                <h2 className="h_color f_36 wow fadeInUp">
                  The place for all your warranties
                </h2>
                <div className="br" />
                <div className="wow fadeInUp" data-wow-delay="300ms">
                  <p>
                    No more looking all over the house for that warranty you put
                    in a random drawer. Store all your warranties on the cloud -
                    to be found easily exactly when you need them.
                  </p>
                  <p />
                  <span className="bold">The concept is easy:</span>
                  <ol>
                    <li> Sign up</li>
                    <li> Add an item</li>
                    <li> Upload purchase proof (capture it with your phone)</li>
                    <li> That's it!</li>
                  </ol>
                </div>
                <a href="#download" id="about-action-button">
                  <span
                    className="n_banner_btn wow fadeInUp"
                    data-wow-delay="450ms"
                  >
                    Get It Now
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default About;

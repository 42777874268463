import React, { Component } from "react";
import Title from "../Title";
import $ from "jquery";
import AndroidSignIn from "../../images/screenshots/screenshot-hand.png";
import FQ1 from "../../images/line/fq_line-1.png";
import FQ2 from "../../images/line/fq_line-2.png";
import FQ3 from "../../images/line/fq_line-3.png";
import FQ4 from "../../images/line/fq_line-4.png";
import FQ5 from "../../images/line/fq_line-5.png";
import FQ6 from "../../images/line/fq_line-6.png";
import FQ7 from "../../images/line/fq_line-7.png";
import FQ8 from "../../images/line/fq_line-8.png";
import FQ9 from "../../images/line/fq_line-9.png";
import FQ10 from "../../images/line/fq_line-10.png";

class Faq extends Component {
  componentDidMount() {
    $(".faq_accordian_two .card").click(function () {
      $(".faq_accordian_two .card").removeClass("active");
      $(this).addClass("active");
    });
  }

  render() {
    return (
      <section
        className="faq_solution_area features_area_pad"
        id="faq"
        data-offset={100}
      >
        <ul className="memphis-parallax hidden-xs hidden-sm white_border">
          <li data-parallax='{"x": -100, "y": 100}'>
            <img className="br_shape" src={FQ1} alt="f_img" />
          </li>
          <li data-parallax='{"x": -200, "y": 200}'>
            <img className="br_shape" src={FQ2} alt="f_img" />
          </li>
          <li data-parallax='{"x": -150, "y": 150}'>
            <img className="br_shape" src={FQ3} alt="f_img" />
          </li>
          <li data-parallax='{"x": 50, "y": -50}'>
            <img className="br_shape" src={FQ4} alt="f_img" />
          </li>
          <li data-parallax='{"x": -150, "y": 150}'>
            <img src={FQ5} alt="f_img" />
          </li>
          <li data-parallax='{"x": -100, "y": 100}'>
            <img src={FQ6} alt="f_img" />
          </li>
          <li data-parallax='{"x": 50, "y": -50}'>
            <img src={FQ7} alt="f_img" />
          </li>
          <li data-parallax='{"x": 250, "y": -250}'>
            <img src={FQ8} alt="f_img" />
          </li>
          <li data-parallax='{"x": 150, "y": -150}'>
            <img src={FQ9} alt="f_img" />
          </li>
          <li data-parallax='{"y": -180}'>
            <img src={FQ10} alt="f_img" />
          </li>
        </ul>
        <div className="container">
          <Title
            Stitle="Frequently Asked Questions"
            pfont="Maybe the one you are looking for is already here"
          />
          <div className="row ">
            <div className="col-md-6 d_flex">
              <div id="accordion" className="faq_accordian_two">
                <div className="card wow fadeInUp active">
                  <div className="card-header" id="heading1">
                    <h5 className="mb-0">
                      <button
                        className="btn btn-link"
                        data-toggle="collapse"
                        data-target="#collapse0"
                        aria-expanded="true"
                        aria-controls="collapse0"
                      >
                        <i className="ti-crown" />
                        Is it really free?
                      </button>
                    </h5>
                  </div>
                  <div
                    id="collapse0"
                    className="collapse show"
                    aria-labelledby="heading1"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        Yes! Warranty Keeper is completely free - You can upload
                        as many items as you want!
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card wow fadeInUp ">
                  <div className="card-header" id="heading2">
                    <h5 className="mb-0">
                      <button
                        className="btn btn-link"
                        data-toggle="collapse"
                        data-target="#collapse1"
                        aria-expanded="true"
                        aria-controls="collapse1"
                      >
                        <i className="ti-user" />
                        Do I have to create an account?
                      </button>
                    </h5>
                  </div>
                  <div
                    id="collapse1"
                    className="collapse "
                    aria-labelledby="heading2"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        Yes. The app does not support using it without creating
                        an account. Creating an account allows you to sync your
                        items to the cloud to make sure you will never lose this
                        information.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card wow fadeInUp ">
                  <div className="card-header" id="heading3">
                    <h5 className="mb-0">
                      <button
                        className="btn btn-link"
                        data-toggle="collapse"
                        data-target="#collapse2"
                        aria-expanded="true"
                        aria-controls="collapse2"
                      >
                        <i className="ti-gallery" />
                        Can I upload more than one receipt?
                      </button>
                    </h5>
                  </div>
                  <div
                    id="collapse2"
                    className="collapse "
                    aria-labelledby="heading3"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        You can add multiple image receipt to every item, but
                        only one PDF receipt (that may have multiple pages).
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card wow fadeInUp ">
                  <div className="card-header" id="heading4">
                    <h5 className="mb-0">
                      <button
                        className="btn btn-link"
                        data-toggle="collapse"
                        data-target="#collapse3"
                        aria-expanded="true"
                        aria-controls="collapse3"
                      >
                        <i className="ti-smallcap" />
                        Can I help translating the app?
                      </button>
                    </h5>
                  </div>
                  <div
                    id="collapse3"
                    className="collapse "
                    aria-labelledby="heading4"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        Sure! Just follow this
                        <a
                          style={{
                            color: "#003459",
                            textDecoration: "underline",
                            marginLeft: 4,
                          }}
                          href="https://docs.google.com/forms/d/e/1FAIpQLSeANGrfeL7XwNkin9iDcDeZe7f70dRZY1OqwEIu25nZ7fuhNQ/viewform"
                        >
                          link
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div
                className="faq_image_mockup wow fadeInUp"
                data-wow-delay="200ms"
              >
                <img
                  src={AndroidSignIn}
                  style={{ maxHeight: 825 }}
                  alt="featured"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Faq;

import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import "./assets/bootstrap.min.css";
import "./assets/animate.css";
import "./assets/elagent/style.css";
import "./assets/themify-icon/themify-icons.css";
import "./assets/font-awesome/font-awesome.min.css";
import "./assets/main.css";
import "./assets/responsive.css";

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import React, { Component } from "react";
import TextPage from "./TextPage";

export default class PrivacyPolicy extends Component {
  render() {
    return <TextPage title="Privacy Policy">{content()}</TextPage>;
  }
}

function content() {
  const headerStyle = { lineHeight: 0 };
  const paragraphStyle = { marginTop: -5, marginBottom: 30 };
  const subtitleStyle = { fontStyle: "italic", marginTop: 10 };
  return (
    <div className="wow fadeInUp" data-wow-delay="300ms">
      <p style={paragraphStyle}>
        <span className="bold">Warranty Keeper</span> ("us", "we", or "our")
        operates the http://www.warranty-keeper.com/ website and the Warranty
        Keeper mobile application (the "Service"). This page informs you of our
        policies regarding the collection, use, and disclosure of personal data
        when you use our Service and the choices you have associated with that
        data. We use your data to provide and improve the Service. By using the
        Service, you agree to the collection and use of information in
        accordance with this policy. Unless otherwise defined in this Privacy
        Policy, terms used in this Privacy Policy have the same meanings as in
        our Terms and Conditions.
      </p>
      <h5 style={headerStyle}> Information Collection And Use </h5>
      <p style={paragraphStyle}>
        We collect several different types of information for various purposes
        to provide and improve our Service to you.
      </p>
      <h5 style={headerStyle}> Types of Data Collected </h5>
      <p />
      <h6 style={subtitleStyle}> Personal Data </h6>
      While using our Service, we may ask you to provide us with certain
      personally identifiable information that can be used to contact or
      identify you ("Personal Data"). Personally identifiable information may
      include, but is not limited to:
      <li>Email address</li>
      <li>First name and last name</li>
      <li>Locale and timezone</li>
      <li>Cookies and Usage Data</li>
      <li>Items you add, their details and receipts</li>
      <h6 style={subtitleStyle}> Usage Data </h6>
      We may also collect information that your browser sends whenever you visit
      our Service or when you access the Service by or through a mobile device
      ("Usage Data"). This Usage Data may include information such as your
      computer's Internet Protocol address (e.g. IP address), browser type,
      browser version, the pages of our Service that you visit, the time and
      date of your visit, the time spent on those pages, unique device
      identifiers and other diagnostic data. When you access the Service by or
      through a mobile device, this Usage Data may include information such as
      the type of mobile device you use, your mobile device unique ID, the IP
      address of your mobile device, your mobile operating system, the type of
      mobile Internet browser you use, unique device identifiers and other
      diagnostic data.
      <h6 style={subtitleStyle}> Tracking & Cookies Data </h6>
      We use cookies and similar tracking technologies to track the activity on
      our Service and hold certain information. Cookies are files with small
      amount of data which may include an anonymous unique identifier. Cookies
      are sent to your browser from a website and stored on your device.
      Tracking technologies also used are beacons, tags, and scripts to collect
      and track information and to improve and analyze our Service. You can
      instruct your browser to refuse all cookies or to indicate when a cookie
      is being sent. However, if you do not accept cookies, you may not be able
      to use some portions of our Service.
      <h6 style={subtitleStyle}> Examples of Cookies we use: </h6>
      <li>Session Cookies. We use Session Cookies to operate our Service.</li>
      <li>
        Preference Cookies. We use Preference Cookies to remember your
        preferences and various settings.
      </li>
      <li>Security Cookies. We use Security Cookies for security purposes.</li>
      <h5 style={headerStyle}> Use of Data </h5>
      <p style={paragraphStyle}>
        Warranty Keeper uses the collected data for various purposes:
        <li>To provide and maintain the Service</li>
        <li>To notify you about changes to our Service</li>
        <li>
          To allow you to participate in interactive features of our Service
          when you choose to do so
        </li>
        <li>To provide customer care and support</li>
        <li>
          To provide analysis or valuable information so that we can improve the
          Service
        </li>
        <li>To monitor the usage of the Service</li>
        <li>To detect, prevent and address technical issues</li>
      </p>
      <h5 style={headerStyle}> Transfer Of Data </h5>
      <p style={paragraphStyle}>
        Your information, including Personal Data, may be transferred to and
        maintained on computers located outside of your state, province, country
        or other governmental jurisdiction where the data protection laws may
        differ than those from your jurisdiction. If you are located outside US
        and choose to provide information to us, please note that we transfer
        the data, including Personal Data, to US and process it there. Your
        consent to this Privacy Policy followed by your submission of such
        information represents your agreement to that transfer. Warranty Keeper
        will take all steps reasonably necessary to ensure that your data is
        treated securely and in accordance with this Privacy Policy and no
        transfer of your Personal Data will take place to an organization or a
        country unless there are adequate controls in place including the
        security of your data and other personal information.
      </p>
      <h5 style={headerStyle}> Disclosure Of Data</h5>
      <p style={paragraphStyle}>
        Warranty Keeper may disclose your Personal Data in the good faith belief
        that such action is necessary to:
        <li>To comply with a legal obligation</li>
        <li>To protect and defend the rights or property of Warranty Keeper</li>
        <li>
          To prevent or investigate possible wrongdoing in connection with the
          Service
        </li>
        <li>
          To protect the personal safety of users of the Service or the public
        </li>
        <li>To protect against legal liability</li>
      </p>
      <h5 style={headerStyle}> Security Of Data</h5>
      <p style={paragraphStyle}>
        The security of your data is important to us, but remember that no
        method of transmission over the Internet, or method of electronic
        storage is 100% secure. While we strive to use commercially acceptable
        means to protect your Personal Data, we cannot guarantee its absolute
        security.
      </p>
      <h5 style={headerStyle}> Service Providers</h5>
      <p style={paragraphStyle}>
        We may employ third party companies and individuals to facilitate our
        Service ("Service Providers"), to provide the Service on our behalf, to
        perform Service-related services or to assist us in analyzing how our
        Service is used. These third parties have access to your Personal Data
        only to perform these tasks on our behalf and are obligated not to
        disclose or use it for any other purpose.
      </p>
      <h5 style={headerStyle}> Analytics</h5>
      <p style={paragraphStyle}>
        We may use third-party Service Providers to monitor and analyze the use
        of our Service.
        <li>
          Google Analytics - You can read the Privacy Policy for Google
          Analytics{" "}
          <a href="https://marketingplatform.google.com/about/analytics/terms/us/">
            here
          </a>
          .
        </li>
        <li>
          Firebase Analytics - You can read the Privacy Policy for Firebase
          Analytics{" "}
          <a href="https://firebase.google.com/policies/analytics">here</a>.
        </li>
        <li>
          Sentry - You can read the Privacy Policy for Sentry{" "}
          <a href="https://sentry.io/privacy/">here</a>.
        </li>
      </p>
      <h5 style={headerStyle}> Links To Other Sites</h5>
      <p style={paragraphStyle}>
        Our Service may contain links to other sites that are not operated by
        us. If you click on a third party link, you will be directed to that
        third party's site. We strongly advise you to review the Privacy Policy
        of every site you visit. We have no control over and assume no
        responsibility for the content, privacy policies or practices of any
        third party sites or services.
      </p>
      <h5 style={headerStyle}> Children's Privacy</h5>
      <p style={paragraphStyle}>
        Our Service does not address anyone under the age of 13 ("Children"). We
        do not knowingly collect personally identifiable information from anyone
        under the age of 13. If you are a parent or guardian and you are aware
        that your Children has provided us with Personal Data, please contact
        us. If we become aware that we have collected Personal Data from
        children without verification of parental consent, we take steps to
        remove that information from our servers.
      </p>
      <h5 style={headerStyle}>Changes To This Privacy Policy</h5>
      <p style={paragraphStyle}>
        We may update our Privacy Policy from time to time. We will notify you
        of any changes by posting the new Privacy Policy on this page. We will
        let you know via email and/or a prominent notice on our Service. You are
        advised to review this Privacy Policy periodically for any changes.
        Changes to this Privacy Policy are effective when they are posted on
        this page.
      </p>
      <h5 style={headerStyle}>Delete Your Account</h5>
      <p style={paragraphStyle}>
        If you wish to delete your account and / or personal data, you can do
        that directly in the application (in the Account page), or contact us at
        app@warranty-keeper.com
      </p>
      <h5 style={headerStyle}>Contact Us</h5>
      <p style={paragraphStyle}>
        If you have any questions about this Privacy Policy, please contact us
        at app@warranty-keeper.com
      </p>
      <p style={paragraphStyle}>
        Our Privacy Policy for Warranty Keeper is created with the help of the{" "}
        <a href="https://www.freeprivacypolicy.com/free-privacy-policy-generator">
          {" "}
          Free Privacy Policy Generator
        </a>
        .
      </p>
    </div>
  );
}

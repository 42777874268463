import React, { Component } from "react";

export default class TextPage extends Component {
  render() {
    return (
      <section
        className="about_area"
        id="about_area"
        style={{ paddingTop: 150 }}
      >
        {/*<ul className="memphis-parallax hidden-xs hidden-sm white_border">*/}
        {/*    <li data-parallax='{"x": -100, "y": 100}'><img className="br_shape"*/}
        {/*                                                   src={require('../../images/line/line_01.png')}*/}
        {/*                                                   alt="f_img"/></li>*/}
        {/*    <li data-parallax='{"x": -200, "y": 200}'><img className="br_shape"*/}
        {/*                                                   src={require('../../images/line/line_02.png')}*/}
        {/*                                                   alt="f_img"/></li>*/}
        {/*    <li data-parallax='{"x": -150, "y": 150}'><img className="br_shape"*/}
        {/*                                                   src={require('../../images/line/line_03.png')}*/}
        {/*                                                   alt="f_img"/></li>*/}
        {/*    <li data-parallax='{"x": 50, "y": -50}'><img className="br_shape"*/}
        {/*                                                 src={require('../../images/line/line_04.png')}*/}
        {/*                                                 alt="f_img"/></li>*/}
        {/*    <li data-parallax='{"x": -150, "y": 150}'><img src={require('../../images/line/line_05.png')}*/}
        {/*                                                   alt="f_img"/></li>*/}
        {/*    <li data-parallax='{"x": -100, "y": 100}'><img src={require('../../images/line/line_06.png')}*/}
        {/*                                                   alt="f_img"/></li>*/}
        {/*    <li data-parallax='{"x": 50, "y": -50}'><img src={require('../../images/line/shape.png')}*/}
        {/*                                                 alt="f_img"/>*/}
        {/*    </li>*/}
        {/*    <li data-parallax='{"x": 250, "y": -250}'><img src={require('../../images/line/shape-1.png')}*/}
        {/*                                                   alt="f_img"/></li>*/}
        {/*</ul>*/}
        {/*<img className="shape wow fadeInRight" src={require('../../images/shape3.png')} alt="f_img"/>*/}
        <div className="container">
          <div className="row row_reverse align_items_center">
            {/*<div className="col-lg-4">*/}
            {/*    <div className="about_img text-right"><img src={require('../../images/app-img.png')}*/}
            {/*                                               alt="f_img"/></div>*/}
            {/*</div>*/}
            <div className="col-lg-12">
              <div className="about_content">
                <h2 className="h_color f_36 wow fadeInUp">
                  {this.props.title}
                </h2>
                <div className="br"></div>
                {this.props.children}

                <a href="/">
                  <span
                    className="n_banner_btn wow fadeInUp"
                    data-wow-delay="450ms"
                  >
                    {" "}
                    Go to Home Page{" "}
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

import React, { Component } from "react";
import Title from "./Title";
import FeaturesItems from "./FeaturesItems";
import FL1 from "../images/line/f_l_01.png";
import FL2 from "../images/line/f_l_02.png";
import FL3 from "../images/line/f_l_03.png";
import FL4 from "../images/line/f_l_04.png";
import FL5 from "../images/line/f_l_05.png";
import FL6 from "../images/line/f_l_06.png";
import FL7 from "../images/line/f_l_07.png";
import FL8 from "../images/line/f_l_08.png";
import FL9 from "../images/line/f_l_09.png";
import FL10 from "../images/line/f_l_10.png";
import IphoneXDark from "../images/screenshots/screenshot-notification.png";

class Features extends Component {
  render() {
    return (
      <section
        className="best_screen_features_area features_area_pad"
        id="features"
        data-offset={150}
      >
        <svg xmlns="http://www.w3.org/2000/svg">
          <path
            fill="#fff"
            id="down_bg_copy_2"
            data-name="down / bg copy 2"
            className="cls-1"
            d="M444.936,252.606c-148.312,0-305.161-29.63-444.936-80.214V0H1920V12S808.194,234.074,444.936,252.606Z"
          />
        </svg>
        <ul className="memphis-parallax hidden-xs hidden-sm white_border">
          <li data-parallax='{"x": -00, "y": 100}'>
            <img className="br_shape" src={FL1} alt="f_img" />
          </li>
          <li data-parallax='{"x": 200, "y": 200}'>
            <img className="br_shape" src={FL2} alt="f_img" />
          </li>
          <li data-parallax='{"x": 150, "y": 150}'>
            <img className="br_shape" src={FL3} alt="f_img" />
          </li>
          <li data-parallax='{"x": 50, "y": 50}'>
            <img className="br_shape" src={FL5} alt="f_img" />
          </li>
          <li data-parallax='{"x": 150, "y": 150}'>
            <img src={FL6} alt="f_img" />
          </li>
          <li data-parallax='{"x": 100, "y": 100}'>
            <img src={FL7} alt="f_img" />
          </li>
          <li data-parallax='{"x": 50, "y": 50}'>
            <img src={FL8} alt="f_img" />
          </li>
          <li data-parallax='{"y": 250}'>
            <img src={FL9} alt="f_img" />
          </li>
          <li data-parallax='{"x": 250, "y": 250}'>
            <img src={FL10} alt="f_img" />
          </li>
          <li data-parallax='{"x": 150, "y": 150}'>
            <img className="br_shape" src={FL4} alt="f_img" />
          </li>
        </ul>
        <div className="container">
          <Title
            Stitle="Features"
            pfont="What makes Warranty Keeper the best warranty tracking app"
          />
          <div className="row ">
            <div className="col-lg-4">
              <div className="b_screen_img wow fadeInUp">
                <img
                  src={IphoneXDark}
                  style={{ maxHeight: 825 }}
                  alt="featured"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-8">
              <div className="row b_features_info">
                <FeaturesItems
                  ftitle="Free Cloud Sync"
                  text="All data is stored and backed up in the cloud - 100% Free!"
                  name="cloud-up"
                />
                <FeaturesItems
                  ftitle="Push Notifications"
                  text="Get a push notification 30 days before a warranty is about to expires"
                  name="bell"
                />
                <FeaturesItems
                  ftitle="Never lose that receipt"
                  text="Download the receipt anytime and anywhere, or share it with your friends and family"
                  name="check-box"
                />
                <FeaturesItems
                  ftitle="Dark Theme"
                  text="Switch between light and dark theme"
                  name="mobile"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Features;
